import { useState, useCallback } from 'react';
import { guestApi } from '../api/guestApi';

export const useGuests = () => {
    const [guests, setGuests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchGuests = useCallback(async () => {
        try {
            setLoading(true);
            const data = await guestApi.fetchGuests();
            setGuests(data);
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const addGuest = useCallback(async (guestData) => {
        try {
            const result = await guestApi.createGuest(guestData);
            await fetchGuests(); // Actualizar la lista después de agregar
            return result;
        } catch (err) {
            throw err;
        }
    }, [fetchGuests]);

    return {
        guests,
        loading,
        error,
        fetchGuests,
        addGuest
    };
};