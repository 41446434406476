import React from 'react';
import logoGoPass from '../assets/logoGoPass.webp';
const Footer = () => {
  return (
    <footer className="bg-zinc-900 text-white py-8">
      <div className="max-w-6xl mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8">
        
        {/* Logo Column */}
        <div className="flex flex-col items-center md:items-start">
          <img 
            src={logoGoPass} 
            alt="Logo para fiestas" 
            className="h-12 mb-4"
          />
          <p className="text-zinc-400 text-sm text-center md:text-left">
            La forma más rápida de organizar tus eventos
          </p>
        </div>

        {/* Info Column */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="text-lg font-semibold mb-4">Organizá con nosotros</h3>
          <p className="text-zinc-400 text-sm text-center md:text-left">
            ¿Querés organizar fiestas y eventos?<br/>
            Contactanos y te ayudamos a hacerlo realidad
          </p>
        </div>

        {/* Contact Column */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="text-lg font-semibold mb-4">Página creada por <strong>Cristian</strong> </h3>
          <div className="space-y-2 text-zinc-400">
            <a href="https://instagram.com" className="flex items-center hover:text-emerald-400 transition-colors">
              <i className="fab fa-instagram mr-2"></i>
              @educacioncristian
            </a>
            <a href="mailto:contacto@tuevento.com" className="flex items-center hover:text-emerald-400 transition-colors">
              <i className="far fa-envelope mr-2"></i>
              crcristian97.cc@gmail.com
            </a>
            <a href="https://wa.me/1234567890" className="flex items-center hover:text-emerald-400 transition-colors">
              <i className="fab fa-whatsapp mr-2"></i>
              +54 351 752 8830
            </a>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
