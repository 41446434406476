import funofthesun from "../assets/f.webp";
import highSessions from "../assets/highsessions.webp";

export const events = [
    {
      id: 1,
      title: "HIGH SESSIONS",
      organizer: "Arturo Reyes", 
      price: "SOLD OUT", 
      date: "24:00 - 13/07/2024",
      image: highSessions,
      tanda1: 12000,
      tanda2: 20000,
      tanda3: 22000,
    },
    {
      id: 1,
      title: "FUN IN THE SUN",
      organizer: "Arturo Reyes", 
      price: "SOLD OUT",
      date: "14:00 - 07/12/2024",
      image: funofthesun,
      },
    {
      id: 2,
      title: "FUN IN THE SUN NEW YEAR",
      organizer: "Arturo Reyes", 
      price: "12000", 
      date: "02:00 - 24/12/2024",
      image: funofthesun,
      tanda1: 12000,
      tanda2: 20000,
      tanda3: 22000,
    },

  
  ]