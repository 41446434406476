import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 3000); // Redirecciona después de 3 segundos

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
                <div className="mb-6">
                    <svg className="mx-auto h-16 w-16 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                </div>
                <h1 className="text-3xl font-bold text-gray-900 mb-4">¡Pago exitoso!</h1>
                <p className="text-gray-600 text-lg">
                    Gracias por tu compra. Tu pago se ha procesado correctamente.
                </p>
                <p className="text-gray-500 mt-4">
                    Serás redirigido a la página principal en unos segundos...
                </p>
            </div>
        </div>
    );
};

export default SuccessPage;
