import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GuestProvider } from './context/GuestContext';
//import { MainLayout } from './layouts/MainLayout';
//import { GuestDashboard } from './pages/GuestDashboard';
import VerifyGuest from './components/guests/VerifyGuest';
import HomeIndex from './pages/HomeIndex';
import TicketPurchase from './components/ticketPurchase';
import SuccessPage from './components/mercadoPagoURL/SuccessPage';
import FailurePage from './components/mercadoPagoURL/FailurePage';
import PendingPage from './components/mercadoPagoURL/PendingPage';
import PaymentPage from './pages/PaymentPage';
function App() {
    console.log('App is rendering');
    return (
        <BrowserRouter>
        <GuestProvider>
            <Routes>
                {/* Public route for guest verification */}
                <Route path="/verify/:guestId" element={<VerifyGuest />} />

                {/* Protected routes within MainLayout 
                <Route path="/mainLayout" element={<MainLayout />}>
                    <Route index element={<GuestDashboard />} />
                    {/* Add other protected routes here
                </Route>*/}

                <Route path="/" element={<HomeIndex />} />
                <Route path="/eventos/:eventId" element={<TicketPurchase />} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/failure" element={<FailurePage />} />
                <Route path="/pending" element={<PendingPage />} />
                <Route path="/payment/:eventId" element={<PaymentPage />} />
            </Routes>
            </GuestProvider>
        </BrowserRouter>
    );
}

export default App;