import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FailurePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 5000); // Redirecciona después de 5 segundos

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
                <div className="mb-6">
                    <svg className="mx-auto h-16 w-16 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
                <h1 className="text-3xl font-bold text-gray-900 mb-4">Pago no completado</h1>
                <p className="text-gray-600 text-lg mb-4">
                    Lo sentimos, hubo un problema al procesar tu pago.
                </p>
                <button 
                    onClick={() => navigate(-1)} 
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300"
                >
                    Intentar nuevamente
                </button>
                <p className="text-gray-500 mt-4">
                    Serás redirigido a la página principal en unos segundos...
                </p>
            </div>
        </div>
    );
};

export default FailurePage;
