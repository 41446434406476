const EventSummary = ({ event, totalServiceFee, quantities, total, grandTotal }) => {
  
  return (
    <div className="space-y-6 bg-zinc-900/50 p-8 rounded-xl h-fit lg:sticky lg:top-6 shadow-lg border border-zinc-800/50">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <span className="text-lg font-semibold text-white">{event?.title}</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="text-orange-500 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="..." />
          </svg>
        </div>
      </div>
      
      <div className="space-y-4">
        <p className="text-zinc-400 text-sm leading-relaxed">{event?.description}</p>

        <div className="border-t border-zinc-800 pt-4 mt-6 space-y-3">
          <div className="flex justify-between text-sm">
            <span className="text-zinc-400">Subtotal</span>
            <span className="text-zinc-300">${total}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-zinc-400">Cargo por servicio</span>
            <span className="text-zinc-300">${totalServiceFee}</span>
          </div>
          <div className="flex justify-between text-base font-medium pt-2 border-t border-zinc-800">
            <span className="text-white">Total</span>
            <span className="text-emerald-400">${grandTotal}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSummary;