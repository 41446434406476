import { useNavigate } from 'react-router-dom';

const HeaderButton = () => {
  const navigate = useNavigate();
  return (
    <button 
      onClick={() => navigate(-1)}
      className="mb-6 flex items-center gap-2 text-cyan-400 hover:text-cyan-300 transition-colors"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="..." />
      </svg>
      Volver
    </button>
  );
};

export default HeaderButton;
