import React from 'react';
import { useParams } from 'react-router-dom';
import { events } from '../utils/events';

function SoldOut() {
    const { eventId } = useParams();
    const event = events.find(e => e.id === parseInt(eventId));

    if (!event) {
        return <div className="min-h-screen bg-zinc-900 text-white p-4 flex items-center justify-center">
            <p>Evento no encontrado...</p>
        </div>;
    }

    const { title, image, organizer, date } = event;

    return (
        <div className="min-h-screen bg-zinc-900 text-white p-4 flex flex-col items-center">
            <div className="w-full max-w-3xl">
                {/* Header */}
                <div className="flex items-center gap-2 justify-center mb-4">
                    <div className="w-8 h-8 rounded-full bg-zinc-800" />
                    <span className="text-xl">{organizer}</span>
                </div>

                <h1 className="text-4xl font-bold text-center mb-8">{title}</h1>

                {/* Main Content */}
                <div className="grid md:grid-cols-2 gap-6">
                    {/* Event Image */}
                    <div className="relative aspect-square bg-zinc-800 rounded-lg overflow-hidden">
                        <img
                            src={image}
                            alt={title}
                            className="w-full h-full object-cover"
                        />
                    </div>

                    {/* Sold Out Message */}
                    <div className="flex flex-col items-center justify-center space-y-6 bg-zinc-800/50 p-8 rounded-lg">
                        <div className="text-5xl font-bold text-red-500">SOLD OUT</div>
                        <p className="text-xl text-zinc-400 text-center">
                            Lo sentimos, este evento está agotado
                        </p>
                        <div className="text-zinc-300">
                            <p className="text-center">{date}</p>
                        </div>
                        <button 
                            onClick={() => window.history.back()}
                            className="px-6 py-3 bg-zinc-700 hover:bg-zinc-600 rounded-lg transition-colors"
                        >
                            Volver a eventos
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SoldOut;
