const FormField = ({ label, id, type = "text", value, onChange }) => (
    <div className="space-y-2">
      <label htmlFor={id} className="block text-sm font-medium">{label} *</label>
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        className="bg-zinc-900/50 border border-zinc-800 text-white w-full p-2 rounded focus:outline-none focus:ring-2 focus:ring-cyan-400"
      />
    </div>
  );
  
  export default FormField;
  