import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PendingPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 5000); // Redirecciona después de 5 segundos

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
                <div className="mb-6">
                    <svg className="mx-auto h-16 w-16 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </div>
                <h1 className="text-3xl font-bold text-gray-900 mb-4">Pago en proceso</h1>
                <p className="text-gray-600 text-lg mb-4">
                    Tu pago está siendo procesado. Te notificaremos cuando se complete la transacción.
                </p>
                <p className="text-gray-500 mt-4">
                    Por favor, no cierres esta ventana mientras procesamos tu pago...
                </p>
                <p className="text-gray-500 mt-2">
                    Serás redirigido a la página principal en unos segundos...
                </p>
            </div>
        </div>
    );
};

export default PendingPage;
