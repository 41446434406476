const TermsAndConditions = ({ checked, onChange }) => (
    <div className="flex items-start space-x-2 pt-4">
      <input
        type="checkbox"
        id="terms"
        checked={checked}
        onChange={onChange}
        className="mt-1 border-cyan-400"
      />
      <div>
        <label htmlFor="terms" className="text-sm">
          Acepto los{' '}
          <span className="text-cyan-400 hover:underline">
            términos y condiciones
          </span>
        </label>
      </div>
    </div>
  );
  
  export default TermsAndConditions;
  