import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { events } from '../utils/events';
import { Link } from 'react-router-dom';
import logoGoPass from '../assets/logoGoPass.webp';
import { useState } from 'react';
import Footer from '../components/footer';

export function EventCard({ title = '', organizer = '', price = '', date = '', image = '', id = '', tanda1 = '', tanda2 = '', tanda3 = '' }) {
  return (
    <div className="relative bg-gray-900 rounded-lg overflow-hidden">
      <div className="aspect-[4/3] relative">
        <img
          src={image}
          alt={`Imagen del evento ${title}`}
          width="100%"
          height="100%"
          className="object-contain w-full h-full"
        />
      </div>
      <div className="p-4 space-y-2">
        <p className="text-sm text-gray-400">{organizer}</p>
        <h2 className="text-xl font-bold">{title}</h2>
        <div className="flex justify-between items-center text-sm">
          <p className="font-bold">{price}</p>
          <p className="text-gray-400">{date}</p>
        </div>
        <Link 
          to={`/eventos/${id}`}
          className="block text-center w-full py-2 bg-emerald-400 text-black font-bold rounded-lg hover:bg-emerald-300 transition-colors"
          aria-label={`Comprar tickets para ${title}`}
        >
          Comprar Tickets
        </Link>
      </div>
    </div>
  );
}

export default function Page() {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredEvents = events.filter(event => {
    const searchLower = searchTerm.toLowerCase();
    return (
      event.title.toLowerCase().includes(searchLower) ||
      event.organizer.toLowerCase().includes(searchLower) ||
      event.date.toLowerCase().includes(searchLower) ||
      event.price.toLowerCase().includes(searchLower)
    );
  });

  return (
    <>
    <div className="min-h-screen bg-black text-white">
      <header className="flex justify-center items-center p-6">
        <div className="text-2xl font-bold text-cyan-400">
          <img src={logoGoPass} alt="Go Pass" className="w-96 h-auto object-contain" width="480" height="100" />
        </div>
      </header>

      <div className="relative h-[40vh] flex items-center justify-center text-center bg-gradient-to-b from-gray-900 to-black">
        <div className="absolute inset-0 bg-black/50 z-10"></div>
        <div className="relative z-20 space-y-3">
          <h1 className="text-4xl md:text-5xl font-bold">
            Comprá tu entrada de forma rápida y segura
          </h1>
          <span className="inline-block px-8 py-3 bg-cyan-400 text-black font-bold rounded-full hover:bg-cyan-300 transition-colors">
            Mira más abajo todos nuestros eventos
          </span>
        </div>
      </div>

      {/* Search Bar */}
      <div className="max-w-xl mx-auto p-4">
        <div className="relative">
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Ej: Fun in the sun"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-gray-900 border border-gray-800 rounded-lg focus:outline-none focus:border-cyan-400"
            aria-label="Buscar eventos"
          />
        </div>
      </div>

      {/* Event Grid */}
      <div className="max-w-7xl mx-auto p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredEvents.map(({id, title, organizer, price, date, image, tanda1, tanda2, tanda3}) => (
          <EventCard
            key={id}
            title={title}
            organizer={organizer}
            price={price}
            date={date}
            image={image}
            id={id}
            tanda1={tanda1}
            tanda2={tanda2}
            tanda3={tanda3}
          />
        ))}
      </div>
    </div>
    <Footer />
    </>
  );
}
