import React, { createContext, useContext } from 'react';
import { useGuests } from '../hooks/useGuests';

const GuestContext = createContext();

export const GuestProvider = ({ children }) => {
    const guestState = useGuests();

    return (
        <GuestContext.Provider value={guestState}>
            {children}
        </GuestContext.Provider>
    );
};

export const useGuest = () => {
    const context = useContext(GuestContext);
    if (!context) {
        throw new Error('useGuest debe ser usado dentro de un GuestProvider');
    }
    return context;
};